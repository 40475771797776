import {Component, OnDestroy} from '@angular/core';
import {Roles} from '../../shared/enums/roles.enum';
import {RightHolder} from '../../shared/model/rightholder';
import {SessionService} from '../../shared/service/session.service';
import {Subscription} from 'rxjs';
import { NgClass, NgFor, NgForOf, NgIf, UpperCasePipe } from '@angular/common';
import { MatMenuModule } from '@angular/material/menu';
import { NgxPermissionsModule } from 'ngx-permissions';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import {Router, RouterLink, RouterLinkActive} from '@angular/router';

@Component({
    selector: 'app-menu-default',
    templateUrl: 'menu-default.component.html',
    styleUrls: ['../menu-common.scss', 'menu-default.component.scss'],
    standalone: true,
    imports: [NgIf, MatMenuModule, NgxPermissionsModule, TranslateModule, MatButtonModule, NgForOf, MatTooltipModule,
        NgFor, UpperCasePipe, RouterLink, NgClass, RouterLinkActive]
})
export class MenuDefaultComponent implements OnDestroy {
    roles: typeof Roles = Roles;

    allowedRightHolders: RightHolder[];
    currentRightHolder: RightHolder;

    allowedRightHoldersSubscription: Subscription;
    rightHolderSubscription: Subscription;

    get workLinkText(): string {
        if (this.sessionService.isSBF) {
            return 'Database alle werken';
        }
        return 'Titels zoeken';
    }
    get rightHolderLinkText() : string {
        if ( this.currentRightHolder.fair ) {
            return 'Mijn FAIR'
        }
        return 'Gegevens Beheerder';
    }

    get rightHolderSearchText() : string {
        if (this.sessionService.isFAIR) {
            return 'FAIR Rechthebbende'
        }
        return 'Rechthebbende';
    }

    constructor(
        public sessionService: SessionService,
        private router: Router
    ) {
        this.allowedRightHoldersSubscription = sessionService.allowedRightHolders$.subscribe(value => {
            this.allowedRightHolders = value;
        });
        this.rightHolderSubscription = sessionService.rightHolder$.subscribe(value => {
            this.currentRightHolder = value;
        });
    }

    ngOnDestroy(): void {
        this.allowedRightHoldersSubscription.unsubscribe();
        this.rightHolderSubscription.unsubscribe();
    }

    switchRightHolder(rightHolder: RightHolder) {
        this.sessionService.switchRightHolder(rightHolder);
    }

    isRightHolderActive(rightHolder: RightHolder) {
        if (this.currentRightHolder != null) {
            return this.currentRightHolder.id === rightHolder.id;
        }
    }

    get showRightHolderLink() {
        return !this.sessionService.isSBF && this.sessionService.hasRole(Roles.RIGHTHOLDER_VIEW);
    }

    get isSbf() {
        return this.sessionService.isSBF;
    }

    get showSwitch(): boolean {
        return (!this.sessionService.isSBF && this.allowedRightHolders.length > 1);
    }

    base() {
        if (this.currentRightHolder != null && this.currentRightHolder.fair ) {
            return '/rightholders/fair'
        }
        return '/rightholders'
    }
}
