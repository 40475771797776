import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { FairService } from 'src/app/shared/service/fair.service';

@Injectable({
  providedIn: 'root'
})
export class FairperiodsResolverService {

  constructor(private fairService: FairService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.fairService.getAllPeriods();
  }
}
