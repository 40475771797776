import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { CommonModule, DatePipe, LocationStrategy, PathLocationStrategy } from '@angular/common';
// import localeNL from '@angular/common/locales/nl';
import '@angular/common/locales/global/nl';
import { environment } from '../environments/environment';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { KeycloakAngularModule, KeycloakOptions, KeycloakService } from 'keycloak-angular';
import { NgxPermissionsModule } from 'ngx-permissions';
import { TemplateComponent } from './shared/template/template.component';
import { SharedModule } from './shared/shared.module';
import { RightHolderInterceptor } from './shared/interceptor/right-holder.interceptor';
import { DialogModule } from './shared/dialog/dialog.module';
import { SharedComponentsModule } from './shared/component/shared-components.module';
import { MenuDefaultComponent } from './menu/menu-default/menu-default.component';
import { MenuRightHolderManagedComponent } from './menu/menu-right-holder-managed/menu-right-holder-managed.component';
import { MenuUserComponent } from './menu/menu-user/menu-user.component';
import { AuthorizationInterceptor } from './shared/interceptor/authorization.interceptor';
import {QuillModule} from "ngx-quill";

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, '/api/translations/', '');
}

// registerLocaleData(localeNL, 'nl');
const keycloakService = new KeycloakService();

@NgModule({
    imports: [
        QuillModule.forRoot(),
        BrowserModule,
        BrowserAnimationsModule,
        KeycloakAngularModule,
        CommonModule,
        SharedModule,
        SharedComponentsModule,
        HttpClientModule,
        AppRoutingModule,
        NgxPermissionsModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        DialogModule,
        AppComponent,
        MenuDefaultComponent,
        MenuRightHolderManagedComponent,
        MenuUserComponent,
        TemplateComponent
    ],
    providers: [
        { provide: KeycloakService, useValue: keycloakService },
        { provide: LocationStrategy, useClass: PathLocationStrategy },
        { provide: LOCALE_ID, useValue: 'nl' },
        { provide: HTTP_INTERCEPTORS, useClass: RightHolderInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: AuthorizationInterceptor, multi: true },
        DatePipe
    ]
})
export class AppModule {
    // noinspection JSUnusedGlobalSymbols
    ngDoBootstrap(app) {
        keycloakService.init(environment.keycloak as KeycloakOptions).then(() => {
            app.bootstrap(AppComponent);
            document.querySelector('.loading').classList.add('hidden');
        }).catch(error => {
            console.error('[ngDoBootstrap] init keycloak failed ', error);
            document.querySelector('.loading').classList.add('hidden');
            document.querySelector('.failed').classList.remove('hidden');
        });
    }
}
